import storeClientData from 'store2';
import moment from 'moment';
import { RequestUtil } from 'pmt-common';
import { initialize } from 'redux-form';
import { GTMUtil } from '@vibrent/electryon';
import * as loginActions from '../components/login/actions';
import i18n from '../../../i18n';
import {
  SET_USER_DETAIL,
  CLEAR_USER_DETAIL,
  SET_USER_ROLE,
  SET_CURRENT_ACTIVE_PROGRAM,
  GET_NODES_START,
  GET_NODES_SUCCESS,
  GET_NODES_FAILURE,
  SET_USER_PERMISSION_WITHOUT_REFRESH,
  SET_PROGRAM_LOGO_AND_ALTTEXT,
  PROGRAM_SWITCH_START,
  PROGRAM_SWITCH_SUCCESS,
  PROGRAM_SWITCH_FAILURE,
  RESET_SELECTED_SITE,
  RESET_SELECTED_MULTI_SITE,
  UPDATE_USER_LANGUAGE_START,
  UPDATE_USER_LANGUAGE_SUCCESS,
  UPDATE_USER_LANGUAGE_FAILURE,
  CHANGE_VIEW_MODE,
  SET_SELECTED_DATE,
  FORCE_CLEAR_ADVANCED_SEARCH_STATE,
  GET_HELP_CENTER_DETAILS_FAILURE,
  GET_HELP_CENTER_DETAILS_START,
  GET_HELP_CENTER_DETAILS_SUCCESS,
  COLLAPSE_NAVBAR,
} from '../events';

import {
  VIBRENT_USER,
  API_GET_NODES,
  API_PROGRAM_SWITCH,
  API_UPDATE_USER_LANGUAGE,
  API_GET_HELP_CENTER_DETAILS,
  NOTIFICATION_TYPES,
} from '../constants';

export const addFeatureTogglePermissions = (userDetails) => {
  const currentActiveProgram = userDetails.currentActiveProgram || {};
  if (currentActiveProgram.vibrentUser && /^[A-Za-z0-9._%+-]+@vibrenthealth.com$/i.test(userDetails.username)) {
    userDetails.currentActiveProgram.permissions.push({
      action: 'view',
      resource: '/programMaintenance/feature/*',
      roleName: 'ROLE_MC_SYSTEM_ADMINISTRATOR',
    });
  }
  return userDetails;
};

export const addSchedulingPermissions = (userDetails) => {
  const permissions = userDetails.currentActiveProgram.roles.filter(role =>
    role.name === 'ROLE_MC_PROGRAM_COORDINATOR' ||
    role.name === 'ROLE_MC_SITE_MANAGER');
  if (permissions.length === 2) {
    userDetails.currentActiveProgram.permissions.push({
      action: 'view',
      resource: '/scheduling/availability/*',
      roleName: 'ROLE_MC_PROGRAM_COORDINATOR',
    },
    {
      action: 'view',
      resource: '/settings/site/*',
      roleName: 'ROLE_MC_PROGRAM_COORDINATOR',
    });
  }
  return userDetails;
};

export const storeUserDetail = (userDetail) => {
  storeClientData(VIBRENT_USER, userDetail.currentActiveProgram.vibrentUser);
  const userDetails = addFeatureTogglePermissions(userDetail);
  const userData = addSchedulingPermissions(userDetails);
  return ({
    type: SET_USER_DETAIL,
    userDetail: userData,
  });
};

export const clearUserDetail = () => ({
  type: CLEAR_USER_DETAIL,
});

export const setUserRole = roleSelected => ({
  type: SET_USER_ROLE,
  roleSelected,
});

export default {
  storeUserDetail,
};

export const updateCurrentActiveProgram = currentActiveProgram => ({
  type: SET_CURRENT_ACTIVE_PROGRAM,
  currentActiveProgram,
});

export const updateUserPermissionWithoutRefresh = permissions => ({
  type: SET_USER_PERMISSION_WITHOUT_REFRESH,
  permissions,
});

export const updateProgramLogoAndAltText = (programLogo, altText) => ({
  type: SET_PROGRAM_LOGO_AND_ALTTEXT,
  programDetails: {
    programLogo,
    altText,
  },
});

export const resetSelectedSite = () => ({
  type: RESET_SELECTED_SITE,
});
export const resetAdvancedSearchState = () => ({
  type: FORCE_CLEAR_ADVANCED_SEARCH_STATE,
});
export const resetSearchState = () => (dispatch) => {
  dispatch(resetAdvancedSearchState());
  dispatch(initialize('SchedulingSearchByParticipantForm', {}));
};
export const resetSelectedMultiSite = () => ({
  type: RESET_SELECTED_MULTI_SITE,
});

/**
 * Change view mode between Day/Week/Month
 * @param  {String} viewMode The new view mode
 * @return {Object} Action data
 */
export const changeViewMode = viewMode => ({
  type: CHANGE_VIEW_MODE,
  payload: {
    viewMode,
  },
});

/**
 * Set selected date for calendar view
 * @param {Moment} selectedDate The selected date
 */
export const setSelectedDate = selectedDate => ({
  type: SET_SELECTED_DATE,
  selectedDate: moment(selectedDate),
});

export const getNodesStart = () => ({
  type: GET_NODES_START,
});

export const getNodesSuccess = res => ({
  type: GET_NODES_SUCCESS,
  res,
});

export const getNodesFailure = err => ({
  type: GET_NODES_FAILURE,
  err,
});

export const getNodes = loginData =>
  async (dispatch, getState) => {
    const userDetail = getState().UserDetail;
    const currentActiveProgram = {
      ...loginData.currentActiveProgram,
      ...loginData.currentActiveProgram.programDetails,
    };
    const gtmData = {
      loginType: loginData.lastLogoutTime ? 'returning' : 'first',
      lastLoginDate: moment(loginData.lastSuccessLoginAt).format('MM/DD/YYYY'),
    };

    await dispatch(getNodesStart());
    return RequestUtil.getData(`${API_GET_NODES}`).then(
      (res) => {
        const userData = {
          userDetail: {
            ...loginData,
            ...loginData.currentActiveProgram,
            ...loginData.currentActiveProgram.programDetails,
          },
          nodeData: res,
          roleSelected: (userDetail && userDetail.get('roleSelected')) || currentActiveProgram.roles[0],
        };
        GTMUtil.pushTagManager(userData, 'login', gtmData);
        dispatch(getNodesSuccess(res));
      },
      err => dispatch(getNodesFailure(err))
    );
  };

/**
 * Refresh current active program
 */
export const refreshCurrentActiveProgram = () =>
  async (dispatch) => {
    const response = await dispatch(loginActions.checkLogin());
    dispatch(updateCurrentActiveProgram(response.currentActiveProgram));
  };

export const refreshUserPermissionWithoutRefresh = () =>
  async (dispatch) => {
    const response = await dispatch(loginActions.checkLogin());
    dispatch(updateUserPermissionWithoutRefresh(response.currentActiveProgram.permissions));
  };

export const programSwitchStart = () => ({
  type: PROGRAM_SWITCH_START,
});

export const programSwitchSuccess = res => ({
  type: PROGRAM_SWITCH_SUCCESS,
  res,
});

export const programSwitchFailure = err => ({
  type: PROGRAM_SWITCH_FAILURE,
  err,
});

export const programSwitch = programId =>
  async (dispatch) => {
    try {
      dispatch(programSwitchStart());
      const url = API_PROGRAM_SWITCH.replace('{programId}', programId);
      const response = await RequestUtil.getData(url);
      dispatch(programSwitchSuccess(response));
      return response;
    } catch (e) {
      dispatch(programSwitchFailure());
      return e;
    }
  };
export const updateUserLanguageStart = () => ({
  type: UPDATE_USER_LANGUAGE_START,
});

export const updateUserLanguageSuccess = res => ({
  type: UPDATE_USER_LANGUAGE_SUCCESS,
  res,
});

export const updateUserLanguageFailure = err => ({
  type: UPDATE_USER_LANGUAGE_FAILURE,
  err,
});

export const updateUserLanguage = locale =>
  async (dispatch) => {
    try {
      dispatch(updateUserLanguageStart());
      const language = locale.language;
      const url = RequestUtil.getParam(API_UPDATE_USER_LANGUAGE, { locale: language });
      const response = await RequestUtil.postData(url, {});
      dispatch(updateUserLanguageSuccess(locale));
      return response;
    } catch (e) {
      dispatch(updateUserLanguageFailure());
      return e;
    }
  };
export const getHelpCentreDetailsStart = () => ({
  type: GET_HELP_CENTER_DETAILS_START,
});

export const getHelpCentreDetailsFailure = error => ({
  type: GET_HELP_CENTER_DETAILS_FAILURE,
  error,
});

export const getHelpCentreDetailsSuccess = response => ({
  type: GET_HELP_CENTER_DETAILS_SUCCESS,
  response,
});

export const getHelpCentreDetails = async token =>
  async (dispatch) => {
    try {
      dispatch(getHelpCentreDetailsStart());
      const url = API_GET_HELP_CENTER_DETAILS;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          Pragma: 'no-cache',
          Authorization: `Bearer ${token}`,
        },
        credentials: 'include',
      }).then((res) => {
        // Check if server return bad response
        // Then throw error
        if (res.status >= 400) {
          throw new Error(i18n.t(NOTIFICATION_TYPES.ERROR_MESSAGE_FROM_SERVER));
        }
        return res.json();
      });
      // Success
      dispatch(getHelpCentreDetailsSuccess(response));
      return response;
    } catch (e) {
      // Dispatch to failure
      dispatch(getHelpCentreDetailsFailure(e));
      return e;
    }
  };
export const colapseNavbar = open => ({
  type: COLLAPSE_NAVBAR,
  open,
});
